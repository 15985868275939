.footer-container{
    width: 100vw;
    background: #111 !important;
    height:fit-content;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 40px 0;
}
.divide-bar-footer{
    width: 100%;
    height: 1px;
    margin-top: -10px;
}
.ant-layout-footer{
    padding: 0;
}
.footer-content{
    width: 85%;
    background: transparent;
    display:flex;
    justify-content: center;
    flex-direction: column;
}
.logo-group-container{
    display:flex;
    justify-content: center;
}


.logo-pirex-container{
    display:flex;
    align-items: center;
    flex-direction: column;
}
@keyframes logoAnim{
    0% {background-position-y: -675px}
    32% {background-position-y: -0px}
    50% {background-position-y: -0px}
    82% {background-position-y: -675px}
}
.logo-pirex-inc{
    opacity: 1;
    background-image: url('../../assets/img/logo-header.png');
    background-position-y: -675px;
    height: 45px;
    width: 45px;
    background-size: cover;
    animation-name: logoAnim;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(15, end);;
}
.text-logo-footer{
    color: rgb(166, 166, 166);
    font-size: 12px;
    font-weight: 700;
    user-select: none;
    padding-left: 20px;
    padding-right: 20px;
    background: #111;
    z-index: 1;
}
.logo-footer{
    font-size: 26px;
    cursor: pointer;
    color: rgb(131, 131, 131);
    width:50px;
    transition: 0.5s;
}
.logo-footer:hover{
    color: #ff1100;
}
.logo-footer-lm{
    font-size: 26px;
    cursor: pointer;
    color: rgb(49, 49, 49);
    width:50px;
    margin-top: 90px;
    transition: 0.5s;
}
.logo-footer-lm:hover{
    color: var(--themeSank-color);
}
.footer-bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 30px 0 10px 0;
}
.policy-button {
    margin-left: 20px;
    cursor: pointer;
}
.policy-text {
    user-select: none;
    font-size: 14px;
    font-weight: 600;
    color: rgb(170, 170, 170);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.policy-button:hover{
    text-decoration: underline;
}
.policy-container{
    display: flex;
    flex-direction: row;
}
@media screen and (max-width:768px){
    .footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column-reverse;
        height: 120px;
    }
}