:root {
  --theme-color: #ff1100;
  --themeSank-color: #be261c;
  --themeSankHover-color: #96231a;
  --mainGray-color: #212121;
  --grayText-color: rgb(170, 170, 170);
  --backDark-color: #111;
  --back-color: #111;
  --elementLight-color: white;
  --elementDark-color: #212121;
  --text-title-dark: rgb(0, 0, 0);
  --text-title-light: white;
  --text-neutral: rgb(126, 126, 126);
}

body {
  background: var(--back-color);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Baloo Bhai 2", sans-serif !important;
  font-optical-sizing: auto;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  background: var(--back-color);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(54, 54, 54);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(53, 53, 53);
}
