.content{
    background: var(--back-color);
    min-height: 100vh;
}
.ant-layout{
    overflow-x: hidden;
}

.content-container {
    padding-top: 70px;
    background: transparent;
    width: 100vw !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
/* @media screen and (max-width: 1600px) {
    .content-container {
        width: 95vw;
    }
} */
@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 1100px) {
}
@media screen and (max-width: 768px) {
}

.text-bold{
    color: #e0e0e0;
    font-size: 12pt;
    font-weight: 500;
    padding-bottom: 20px;
}

.text-regular{
    color: rgb(189, 189, 189);
    font-size: 11pt;
    font-weight: 400;
    padding-bottom: 20px;
}
.text-bold-lm{
    color: var(--text-title-dark);
    font-size: 12pt;
    font-weight: 500;
    padding-bottom: 20px;
}
.text-regular-lm{
    color: rgb(0, 0, 0);
    font-size: 11pt;
    font-weight: 400;
    padding-bottom: 20px;
}
.text-theme-color{
    color: rgb(55, 162, 204);
}
.text-theme-color:hover{
    color: white;
}
.text-theme-color-lm{
    color: rgb(0, 128, 100);
    font-weight: 500;
}
.text-theme-color-lm:hover{
    color: rgb(0, 96, 121);
}
.d-flex {
    display: flex;
}
.j-center{
    justify-content: center;
}
.j-space-between{
    justify-content: space-between;
}
.flex-column{
    flex-direction: column;
}
.alg-center{
    align-items: center;
}
.alg-end{
    align-items: flex-end;
}
.j-end{
    justify-content: flex-end;
}
.ant-drawer{
    z-index:10000000;
}
.btn-mode *{
    max-height: 20vw;
    position: absolute;
}
.main-img-btn-mode, .smoke-img-btn-mode{
    height: 20vw;
    width: 20vw;
    display: flex;
    justify-content: center;
    object-position: 50% 50%;   
    align-items: center;
}
.main-img-btn-mode-container{
    cursor: pointer;
    height: 12vw;
    width: 12vw;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    overflow: hidden;
    background-color: #000000;
 
}
.main-img-btn-mode-container img {
    opacity: 0.35;
    transition: .3s;
}
.b-radius-50{
    border-radius: 50%;
    clip-path:none !important;
}
.main-img-btn-mode img {
    object-fit: cover;   
    object-position: 50% 50%;
    height: 100%;
}
.p-rel{
    position: relative;
}
.smoke-img-btn-mode img{
    height: 16.5vw;
}
.btn-get-on-ggplay{
    color:rgba(220, 220, 220, 0.796);
    margin-top: -140px;
    height: 60px;
    padding:0 30px;
    background-color: rgb(28, 28, 28);
    border-radius: 10px;
    font-weight: 500;
    border-color:rgb(0, 214, 132);
}
.btn-get-on-ggplay:hover{
    background-color: rgba(0, 118, 73, 0.487);
    border-color:rgb(0, 167, 103);
}
.btn-get-on-ggplay:focus{
    background-color: rgba(0, 118, 73, 0.411);
    border-color:rgb(0, 167, 103);
}
.ant-layout-header{
    padding:0;
}
@media screen and (max-width:1600px){
    .btn-mode *{
        max-height: 27vw;
    }
    .main-img-btn-mode, .smoke-img-btn-mode{
        height: 27vw;
        width: 27vw;
    }
    .main-img-btn-mode-container{
        height: 16vw;
        width: 16vw;
    }
    .smoke-img-btn-mode img{
        height: 22vw;
    }
}
@media screen and (max-width:768px){
    .btn-mode *{
        max-height: 80vw;
    }
    .main-img-btn-mode, .smoke-img-btn-mode{
        height: 80vw;
        width: 80vw;
    }
    .main-img-btn-mode-container{
        height: 52vw;
        width: 52vw;
    }
    .smoke-img-btn-mode img{
        height: 65vw;
    }
}