.header {
  position: fixed;
  z-index: 1000000;
  width: 100%;
  height: 75px;
  display: flex;
  overflow-x: hidden;
  margin: auto;
  padding: auto;
  transition-duration: 0.3s;
  background-color: black !important;
}
.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.logo-header-container {
  height: 75px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
}
.logo-header {
  margin-top: 2px;
  background-image: url("../../assets/img/logo-header.png");
  background-position-y: -825px;
  cursor: pointer;
  height: 55px;
  width: 55px;
  background-size: cover;
  transition: background-position 0.3s steps(15, end);
  position: absolute;
  transition-duration: 0.3s;
}
.logo-header-dark {
  margin-top: 2px;
  background-image: url("../../assets/img/logo-header-dark.png");
  background-position-y: -825px;
  cursor: pointer;
  height: 55px;
  width: 55px;
  background-size: cover;
  transition: background-position 0.3s steps(15, end);
  position: absolute;
  transition-duration: 0.6s;
}
.logo-header:hover {
  background-position-y: -0px;
}
.logo-header-dark:hover {
  background-position-y: -0px;
}
.icon-dropdown-logo {
  margin-left: 65px;
  color: rgb(136, 136, 136);
}
.icon-dropdown-logo:hover {
  color: rgb(255, 255, 255);
}
.ant-btn > .anticon {
  line-height: 0 !important;
}
.divide-container {
  height: 70px;
  display: flex;
  align-items: center;
  margin-left: 110px;
  margin-top: 2px;
}
.divide-bar-logo-header {
  height: 27px;
  width: 1px;
  background: rgb(109, 109, 109);
  z-index: 2;
}
.tabs-left {
  width: fit-content;
  text-align: center;
  margin-top: 0;
  font-weight: bold;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}
.tab-btn-active {
  color: white;
}

.tab-btn-inactive {
  color: var(--grayText-color);
  border-color: transparent;
}

.tab-btn-active,
.tab-btn-inactive {
  min-width: 80px;
  height: 75px;
  font-weight: 600;
  font-size: 14px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bottom-tab-active,
.bottom-tab-inactive {
  width: 100%;
  height: 4px;
  background-color: var(--theme-color);
  transition: 0.3s;
  border-radius: 3px;
}
.bottom-tab-inactive {
  width: 0px;
  background-color: transparent;
}
.tab-btn-active *,
.tab-btn-inactive * {
  transition: 0.2s;
}
.tab-btn-inactive:hover .bottom-tab-inactive {
  width: 100%;
  background-color: var(--grayText-color);
}
.icon-tab {
  height: 100%;
  width: 45px;
  background-color: var(--theme-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-container-tab,
.title-container-tab-inactive {
  width: 100%;
  height: 60px;
  padding: 0px 10px 0px 10px;
}
.tabs-right {
  display: flex;
  align-items: center;
}
.button-login {
  height: 44px;
  background-color: var(--themeSank-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 30px;
  border-radius: 44px;
  cursor: pointer;
  font-weight: 500;
}
.tabs-right Button {
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  color: white;
  border-width: 0px;
  height: 75px;
  width: 130px;
}
.tabs-right Button:focus {
  background: transparent;
  color: white;
}

.tabs-right Button:hover {
  background: transparent;
  color: white;
}
.tabs-right Button:hover .icon-android {
  color: var(--theme-color) !important;
}
.logo-dropdown {
  background: var(--mainGray-color);
  margin-top: 10px;
}
.logo-dropdown a {
  color: var(--grayText-color);
  font-weight: 600;
  font-size: 12px;
}
.logo-dropdown a:hover {
  color: white;
}
.platform-dropdown {
  background: var(--mainGray-color);
}
.platform-dropdown a {
  color: var(--grayText-color);
  font-weight: 700;
  font-size: 12px;
}
.platform-dropdown a:hover {
  color: white;
}
.platform-dropdown-item {
  height: 45px;
  width: 200px;
}
.platform-dropdown-item:hover {
  background-color: rgb(68, 68, 68);
}
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-logout {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.349);
  border-radius: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.431);
  margin-left: 24px;
}
.button-logout:hover {
  background-color: rgb(32, 32, 32);
}
.account-info img {
  height: 44px;
  width: 44px;
  border-radius: 22px;
  border: 2px solid rgb(232, 232, 232);
  margin-top: 3px;
}
.pirex-id {
  margin-left: 14px;
  font-weight: 600;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  text-transform: uppercase;
}
.drawer-body-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.drawer-tab-container {
  display: flex;
  flex-direction: column;
}
.drawer-account {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.button-login-drawer {
  width: 300px !important;
  height: 60px !important;
  border-radius: 0 !important;
}
