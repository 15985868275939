.download-prexcode{
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main-content{
    padding: 50px 10vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    background-color: var(--back-color);
}
.img-code-editor{
    width: 55%;
    overflow: visible !important;
}
.img-code-editor img{
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
}
.img-code-editor .img1{
    margin-top: -22vw;
    margin-left: 34px;
}
.btn-download{
    margin-top: 30px;
    cursor: pointer;
    height: fit-content;
    width: 250px;
    background: var(--themeSank-color);
    border-radius: 3px;
    padding: 10px 0 5px 0;
}
.btn-download:hover{
    background: rgba(255, 17, 0, 0.678);
}
.btn-download * {
    color: white;
    font-family: "Segoe UI","Helvetica Neue","Helvetica",Arial,sans-serif;
}
.btn-download h4{
    margin-top: -10px;
    font-weight: 400;
}
.dowload-prexcode-title, .btn-download{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dowload-prexcode-title *{
    color: rgb(241, 241, 241);
}
.dowload-prexcode-title h1{
    font-size: 30px;
}
.dowload-prexcode-title h2{
    font-size: 15px;
    color: rgb(197, 197, 197);
}

@media screen and (max-width:1100px){
    .main-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        align-items: center;
    }
    .img-code-editor{
        width: 90%;
    }
    .img-code-editor .img1{
        margin-top: -45vw;
        margin-left: 20px;
    }
    .download-prexcode{
        width: 90%;
        margin-top: 50px;
    }
}