.title {
    text-align: center;
}

.divide-bar{
    width: 100%;
    height: 1px;
    margin-top: 50px;
}
.content-container-policy {
    margin-top: 0px;
    background: transparent;
    max-width: 1100px;
    min-width: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    padding-top: 70px;
}
.content-container-policy * {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
@keyframes start-policy {
    from{margin-top: 100px; opacity: 0;}
    to{margin-top: 50px; opacity: 1;}
}
