.my-fade-enter {
    opacity: 0;
}
.my-fade-enter-active {
    opacity: 1;
    position: 0;
    transition: opacity 600ms;
}
.my-fade-exit {
    opacity: 1;
}
.my-fade-exit-active {
    opacity: 0;
}