.ourgames-container {
  background-color: #000000;
  width: 100vw;
}
.title-ourgames {
  color: rgb(255, 255, 255);
  font-size: 23px;
  font-weight: 700;
  margin: auto;
  text-align: center;
  background-color: #111;
  padding: 15px 50px;
  width: 250px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border: 1px solid rgb(46, 46, 46);
}
.list-game-carousel-container {
  background-color: #111;
  scroll-behavior: smooth !important;
  overflow-y: hidden !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
