.game-item-carousel {
  background-color: transparent;
  height: 95vh;
  width: 100vw;
  min-height: 910px;
}

.game-item-content {
  overflow: hidden !important;
  height: 70%;
}
.game-item-image {
  height: 100%;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.game-item-image * {
  z-index: 2;
}

.game-item-bg {
  z-index: 1;
  position: absolute;
  height: 66.5%;
  width: 100%;
  overflow: hidden !important;
  user-select: none;
  pointer-events: none;
}
.game-item-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-item-smoke {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 300px;
  bottom: 0;
}
.game-item-footer {
  height: 30%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-item-footer-store {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 20px;
}

.game-item-footer-title {
  padding: 30px 10px 0px 10px;
  color: #141414e1;
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  pointer-events: none;
  user-select: none;
}

.game-item-footer-description {
  margin-top: -10px;
  padding: 0px 10px 0px 10px;
  color: #3f3f3fe1;
  text-align: center;
  font-size: 16px;
  pointer-events: none;
  user-select: none;
  font-weight: 400;
}
.custom-style {
  height: 60px !important;
  margin: 0 10px 0 10px;
  border-radius: 20px !important;
}
.game-rate-point {
  margin-left: -30%;
  margin-top: -10px;
  height: 120%;
  width: 16%;
  /* border-top-right-radius: 15px;
    border-bottom-right-radius: 15px; */
  background-color: #141414e1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.3s;
}
.rate-point {
  margin-top: 10px;
  height: 22%;
  width: 80%;
  border: 1px solid rgb(116, 116, 116);
  border-radius: 20px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rate-point svg {
  fill: rgb(181, 181, 181);
  height: 40%;
  width: 40%;
}

.game-item-footer-outline {
  height: 1px;
  width: 80vw;
  background-color: #1010102f;
  margin-top: 30px;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  36% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 1400px) {
  .game-rate-point {
    height: 120%;
    width: 20%;
  }
  .rate-point {
    border-radius: 16px;
  }
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 900px) {
  .game-item-carousel {
    border-radius: 12px;
  }
  .game-rate-point {
    margin-left: 0;
  }
}
@media screen and (max-width: 700px) {
  .rate-point {
    font-size: 9px;
  }
  .game-item-carousel {
    height: 110vh;
  }

  .game-rate-point {
    width: 25%;
  }

  .rate-point {
    border-radius: 8px;
  }

  .game-item-footer-store {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    gap: 10px;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-btn {
  width: 45%;
  max-width: 160px;
  color: #fff;
  margin: 20px 10px;
  text-align: left;
  border-radius: 5px;
  text-decoration: none;
  font-family: "Lucida Grande", sans-serif;
  font-size: 10px;
  text-transform: uppercase;
}
.blu {
  background-color: #101010;
  transition: background-color 0.25s linear;
}
.app i {
  width: 20%;
  text-align: center;
  font-size: 28px;
  margin-right: 7px;
}
.big-txt {
  font-size: 17px;
  text-transform: capitalize;
}
