.language-dropdown {
  display: inline-block;
  z-index: 1000; /* Ensure it appears above other elements */
  height: 50px;
  margin-right: 20px;
}

.language-dropdown * {
  user-select: none;
}
.flag-icon {
  border-radius: 35px;
  overflow: hidden;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.selected-language {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  background-color: #00000060;
  border: 1px solid #dddddd3f;
  border-radius: 30px;
  transition: background-color 0.3s ease;
  height: 100%;
}

.selected-language:hover {
  background-color: #2c2c2c;
}

.selected-language span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}

.dropdown-list {
  width: 200px !important;
  position: fixed;
  top: 60px;
  right: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 15px;
  margin-top: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2000; /* Stack above other content */
  width: 100%;
  overflow: hidden;
  height: fit-content;
  overflow-y: hidden;
}

.dropdown-item {
  padding: 0px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.dropdown-item:active {
  background-color: #ddd;
}

.dropdown-item img {
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
