.modal-content {
  height: 100%;
  max-width: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 70px 80px 0px 80px;
  line-height: 30px;
  border-radius: 2px;
}
.game-icon {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.281);
  user-select: none;
  pointer-events: none;
}

.game-icon img {
  height: 100%;
  width: 100%;
}

.title-modal {
  padding: 10px 0px;
  margin-top: 15px;
  font-size: 32px;
  font-weight: bold;
  background: linear-gradient(to top, #ffffff 0%, #a3a3a3 100%);
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
  text-align: center;
  user-select: none;
}
.genres-game-modal {
  font-size: 14px;
  font-weight: 600;
  color: rgb(155, 155, 155);
  user-select: none;
  pointer-events: none;
}
.game-reviews-modal {
  display: flex;
  height: 100px;
  width: 300px;
  justify-content: space-between;
  margin-top: 10px;
}
.rate-point-modal {
  padding-top: 5px;
  margin-top: 0px;
  width: 30%;
  height: 70%;
  border-radius: 15px;
  cursor: default;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(116, 116, 116, 0.575);
  color: white;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(45deg, #000000c2 0%, #000000c5 100%);
  transition: 0.3s;
  user-select: none;
}

.game-item-summary {
  font-size: 17px;
  color: rgb(255, 255, 255);
  max-width: 600px;
  text-decoration: dotted;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 120px;
  line-clamp: 4;
  overflow-y: auto;
  text-overflow: ellipsis;
  user-select: none;
}

.game-item-summary::-webkit-scrollbar-track {
  background: #11111100 !important;
}

.game-item-summary::-webkit-scrollbar-thumb {
  background: rgba(54, 54, 54, 0);
  border-radius: 100px;
}

/* Handle on hover */
.game-item-summary::-webkit-scrollbar-thumb:hover {
  background: rgba(53, 53, 53, 0);
}

.rate-point-modal svg {
  height: 24px;
  width: 24px;
  fill: rgb(181, 181, 181);
}
.rate-point-modal:hover {
  background-color: #ff000083;
}
.button-get-game-container {
  display: flex;
  padding-bottom: 10px;
}
.button-get-game {
  height: 52.3px;
  width: 170px;
  background-color: #00000000;
  cursor: pointer;
}
.button-get-game img {
  height: 100%;
  width: 100%;
}

.button-see-now {
  margin-top: 20px;
  height: 65px;
  width: 190px;
  background: linear-gradient(45deg, #ac0000 0%, #971a1a7e 100%);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 0 20px 0 50px;
  border: 1px solid rgba(148, 148, 148, 0.301);
  border-radius: 30px;
  transition-duration: 0.3s;
  user-select: none;
}
.text-see-now {
  margin-top: 4px;
  margin-left: -30px;
  padding-right: 20px;
}
.button-see-now:hover {
  background-color: #ffd900;
}
.button-see-now:hover .icon-see-now {
  box-shadow: rgba(255, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.icon-see-now {
  border-radius: 100px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.game-item-logo-publisher img {
  object-fit: contain;
  height: 100px;
  width: 100px;
  right: 30px;
  top: 90px;
  position: absolute;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 700px) {
  .title-modal {
    font-size: 24px;
  }
  .game-item-summary {
    max-height: 150px;
    font-size: 15px;
    text-align: center;
  }
  .game-reviews-modal {
    display: flex;
    height: 100px;
    width: 240px;
    justify-content: space-between;
    margin-top: 10px;
  }
  .rate-point-modal {
    font-size: 11px;
  }
  .rate-point-modal svg {
    height: 18px;
    width: 18px;
  }
  .modal-content {
    padding: 40px 20px 0 20px;
    align-items: center;
  }
}

@font-face {
  font-family: "fontfutura";
  src: url("https://cdn.rawgit.com/KompiAjaib/font/master/product-sans-regular.ttf")
    format("ttf");
  font-weight: normal;
  font-style: normal;
}
