.header-container-res {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-maxwidth-769 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}
.logo-container-res {
  margin-top: 5px;
  height: 36px;
  width: 116px;
  background-size: contain;
  background-repeat: no-repeat;
}
.btn-show-drawer {
  cursor: pointer;
  position: relative;
  margin-right: 25px;
  margin-top: 13.5px;
  height: 43px;
  width: 43px;
  background: rgb(24, 24, 24);
  border-radius: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-in-drawer {
  cursor: pointer;
  color: white;
  font-weight: 600;
  height: 70px;
  border-width: 1px;
  border-color: rgb(141, 141, 141);
  background: rgb(29, 29, 29);
  text-align: left;
  padding-left: 30px;
  margin-top: 2px;
  transition: 0.5s;
  display: flex;
  align-items: center;
}
.btn-in-drawer:hover {
  background: rgb(53, 53, 53);
}

.ant-drawer-close svg {
  color: white !important;
  fill: white !important;
}
.anticon-close {
  height: 30px !important;
  width: 30px !important;
}
.ant-drawer-body {
  background: rgb(24, 24, 24);
}
.ant-drawer-header {
  background-color: rgb(19, 19, 19);
  border-radius: 0;
  height: 70px !important;
  display: flex;
  align-items: center;
}
.ant-drawer-title {
  color: rgb(185, 185, 185);
}
.icon-drawer {
  color: rgb(206, 206, 206);
  font-size: 21px;
}
